import ApiMethods from "../ApiMethods";
import ENDPOINTS from "../EndPoints";

class QueueManager {
	static ENDPOINTS = {
		GETLIST: () => `/getrequests`,
		GETREQUESTFILES: () => `/getrequestfiles`,
		GETREQUESTCOMMENT: () => `/getcomments`,
		ADDCOMMENT: () => `/addcomment`,
		ADDFIXER: () => `/addfix`,
		ADDREQUEST: () => `/addrequest`,
		GETREQUEST: () => `/getrequest`,
		ADDFIX: () => `/addfix`,
	};

	static GETQUEUE = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETLIST();
		return ApiMethods.get(url);
	};

	static GETREQUESTFILES = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETREQUESTFILES();
		return ApiMethods.post(url, body);
	};

	static GETREQUESTCOMMENT = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETREQUESTCOMMENT();
		return ApiMethods.post(url, body);
	};

	static ADDCOMMENT = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ADDCOMMENT();
		return ApiMethods.post(url, body);
	};

	static ADDFIXER = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ADDFIXER();
		return ApiMethods.post(url, body);
	};

	static ADDREQUEST = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ADDREQUEST();
		return ApiMethods.post(url, body);
	};

	static GETREQUEST = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.GETREQUEST();
		return ApiMethods.post(url, body);
	};

	static ADDFIX = (body) => {
		const url = ENDPOINTS.BASE_URL + this.ENDPOINTS.ADDFIX();
		return ApiMethods.post(url, body);
	};
}

export default QueueManager;
