import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { FormControl, TextField, Autocomplete, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import { useSelector } from 'react-redux';
import Table from '../../components/table/Table';
import HoldButton from '../../components/holdbutton/HoldButton';

const statusOptions = [
  { value: 1, label: 'Production' },
  { value: 2, label: 'Shipped' },
];

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  const debouncedFunction = useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);

  return debouncedFunction;
};

const StatusChangeModal = ({ isOpen, onClose, onSubmit, destination, setDestination }) => {

  const [localDestination, setLocalDestination] = useState(destination);
  const debouncedSetDestination = useDebounce(setDestination, 300);

  useEffect(() => {
    debouncedSetDestination(localDestination);
  }, [localDestination, debouncedSetDestination]);

  useEffect(() => {
    setLocalDestination(destination);
  }, [destination]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Enter Destination</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Destination"
          type="text"
          fullWidth
          value={localDestination}
          onChange={(e) => setLocalDestination(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} disabled={!localDestination}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
function StatusChangeBoard() {

  const useQuery = () => {
    return React.useMemo(() => {
      const params = new URLSearchParams(window.location.search);
      const queryObject = {};

      params.forEach((value, key) => {
        queryObject[key] = value;
      });

      return queryObject;
    }, [window.location.search]);
  };

  const queryObject = useQuery();

  const [parts, setParts] = useState([]);
  const [currentPartNumber, setCurrentPartNumber] = useState(null);
  const [serials, setSerials] = useState([]);
  const [updateFetchSerials, setUpdateFetchSerials] = useState(0)
  const [logData, setLogData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStatusChange, setCurrentStatusChange] = useState(null);
  const [destination, setDestination] = useState('');
  const handleApiError = ApiError();
  const logRef = useRef(null);
  const authData = useSelector((state) => state.auth);
  const { user } = authData;

  useEffect(() => {
    (async () => {
      try {
        const response = await ApiManager.PARTS.LIST_IDS();
        const responseData = await handleApiError(response);
        if (response.error) {
          console.error('API Error:', response.error);
          return;
        }

        const ps = responseData.map(part => ({ id: part.pid, name: part.Name }))

        setParts(ps);

        if (queryObject && queryObject.part) {
          setCurrentPartNumber(ps.find((p) => p.name == queryObject.part).id)
        }
      } catch (error) {
        console.error('Failed to fetch parts:', error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSerials = async (partNumber) => {
    try {
      const response = await ApiManager.STATUS.SERIALPROD(partNumber);
      const responseData = await handleApiError(response);
      if (response.error) {
        console.error('API Error:', response.error);
        return;
      }
      setSerials(responseData);
      setUpdateFetchSerials(updateFetchSerials + 1)
    } catch (error) {
      console.error('Failed to fetch serials:', error);
    }
  };

  const fetchLogData = async (sid) => {
    try {
      const response = await ApiManager.STATUS.SERIALPROD_LOG(sid); // Replace with actual API call
      const responseData = await handleApiError(response);
      if (response.error) {
        console.error('API Error:', response.error);
        setLogData([]); // Ensure logData is reset to an empty array on error
        return;
      }
      console.log("LOGS", responseData);
      setLogData(responseData || []); // Set logData to an empty array if responseData is null or undefined
      logRef.current.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Failed to fetch log data:', error);
      setLogData([]); // Ensure logData is reset to an empty array on error
    }
  };

  useEffect(() => {
    if (currentPartNumber) {
      fetchSerials(currentPartNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartNumber]);

  const handleStatusChange = async (sid, newStatus) => {
    if (newStatus === 2) { // Assuming 2 is the ID for "Shipped"
      setCurrentStatusChange({ sid, newStatus });
      const newSerials = [...serials];
      newSerials[newSerials.findIndex((row) => row.sid === sid)].status = newStatus;
      setSerials(newSerials)
      setUpdateFetchSerials(i => i + 1)
      setIsModalOpen(true);
    } else {
      try {
        console.log("user", user)
        const response = await ApiManager.STATUS.CHANGE_SERIALPROD(sid, {
          production_status_id: newStatus,
          user: user, // Replace with the actual user if available
        });
        await handleApiError(response);
        fetchSerials(currentPartNumber); // Re-fetch serials after status change
        fetchLogData(sid); // Fetch logs for the specific serial after status change
      } catch (error) {
        console.error('Failed to change status:', error);
      }
    }
  };

  const handleRowClick = (params) => {
    fetchLogData(params.row.sid);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setDestination('');
    setCurrentStatusChange(null);
    // Optionally revert the status change if necessary
    fetchSerials(currentPartNumber); // Re-fetch serials to revert the status change visually
  };

  console.log(authData)

  const handleModalSubmit = async () => {

    if (currentStatusChange) {
      const { sid, newStatus } = currentStatusChange;
      setCurrentStatusChange(currentStatusChange);
      try {
        const response = await ApiManager.STATUS.CHANGE_SERIALPROD(sid, {
          production_status_id: newStatus,
          user: user,
          destination: destination,
        });
        await handleApiError(response);
        fetchSerials(currentPartNumber); // Re-fetch serials after status change
        fetchLogData(sid); // Fetch logs for the specific serial after status change
      } catch (error) {
        console.error('Failed to change status:', error);
      }
    }
    setIsModalOpen(false);
    setDestination('');
    setCurrentStatusChange(null);
  };

  const serialColumns = [
    { field: 'sid', headerName: 'ID', flex: 0.125, width: 90 },
    {
      field: 'serial_name', headerName: 'Serial Name', flex: 0.25, width: 200, renderCell: (params) => {
        const url = `${process.env.REACT_APP_LOCAL_ROUTE}/log/${params.value}?sid=${params.id}`;
        return (
          <HoldButton params={params} url={url} color={"#2d5ca9"} fontSize={params.row.fontSize} />
        );
      }
    },
    {
      field: 'production_status',
      headerName: 'Status',
      flex: 0.25,
      width: 200,
      renderCell: (params) => (
        <Select
          value={params.row.production_status}
          onChange={(event) => handleStatusChange(params.row.sid, event.target.value)}
          style={{ width: '100%', margin: '0' }}
          disabled={!["Fan Brumsted", "Alden Minard", "Shawn Knapp", "Julio Morales"].includes(authData.user)}
        >
          {statusOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ),
      valueGetter: (params) => statusOptions.find((ele) => ele.value == params.row.production_status).label,
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 0.375,
      width: 300,
    },
  ];


  const logColumns = [
    { field: 'log_id', headerName: 'Log ID', flex: 0.125, width: 90 },
    { field: 'serial_production_status_id', headerName: 'Serial Production Status ID', flex: 0.25, width: 200 },
    { field: 'old_status_name', headerName: 'Old Status', flex: 0.125, width: 150 },
    { field: 'new_status_name', headerName: 'New Status', flex: 0.125, width: 150 },
    { field: 'changed_by', headerName: 'Changed By', flex: 0.25, width: 200 },
    { field: 'change_date', headerName: 'Change Date', flex: 0.25, width: 200 },
  ];


  const mappedSerials = serials.map(serial => {
    const statusOption = statusOptions.find(option => option.label === serial.production_status);
    return { ...serial, production_status: statusOption ? statusOption.value : serial.production_status };
  });

  return (
    <div style={{ margin: 20 }}>
      <FormControl fullWidth style={{ marginBottom: 20 }}>
        <Autocomplete
          options={parts}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          style={{ width: 300, marginBottom: 20 }}
          renderInput={(params) => <TextField {...params} label="Select Part Number" variant="outlined" />}
          value={parts.find(part => part.id === currentPartNumber) || null}
          onChange={(event, newValue) => {
            if (newValue) {
              setCurrentPartNumber(newValue.id);
              setLogData([]);
            } else {
              setCurrentPartNumber(null);
              setSerials([]);
              setUpdateFetchSerials(updateFetchSerials + 1)
              setLogData([]);
            }
          }}
        />
      </FormControl>

      {mappedSerials.length > 0 && (
        <div>
          <Table
            columns={serialColumns}
            fetchData={() => {
              return { result: mappedSerials.map((s, i) => { console.log(s); return { ...s, id: s.sid } }), size: mappedSerials.length }
            }}
            sx={{ height: '500px' }}
            id={"serials"}
            onCellClick={() => { }}
            doFetchIndex={updateFetchSerials}
            paginationMode={"client"}
            location={"tableData_status_serials"}
            onRowClick={handleRowClick}
          />
          <div ref={logRef} style={{ marginTop: 50 }}>
            {logData && logData.length > 0 ? (
              <Table
                columns={logColumns}
                fetchData={() => {
                  return { result: logData.map((s) => { return { ...s, id: s.log_id } }), size: logData.length }
                }}
                sx={{ height: '500px' }}
                id={"serialsLog"}
                onCellClick={() => { }}
                doFetchIndex={logData}
                paginationMode={"client"}
                location={"tableData_status_log"}
              />
            ) : (
              <div style={{ height: 400, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p>No log data available</p>
              </div>
            )}
          </div>
        </div>
      )}

      <StatusChangeModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        destination={destination}
        setDestination={setDestination}
      />
    </div>
  );
}

export default StatusChangeBoard;
