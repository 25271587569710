import React, { useState, useEffect, useRef } from 'react';
import './queueboard.css'

import { QueueForm } from '../../components'

import ApiManager from '../../api_utils/ApiManager';
import ApiError from '../../api_utils/ApiError';
import Table from '../../components/table/Table';
import { Modal } from '@mui/material';
import CustomSnackbar from '../../components/snackbar/CustomSnackbar';
import SendIcon from '@mui/icons-material/Send';
import HoldButton from '../../components/holdbutton/HoldButton';
import CloseIcon from '@mui/icons-material/Close';
import JiraIcon from '../../icons/JiraIcon';
export default function QueueBoard() {

	const [openForm, setOpenForm] = useState(false)
	const [clickedRow, setClickRow] = useState({})
	const [rows, setRows] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [currentFile, setCurrentFile] = useState({})
	const [comments, setComments] = useState([])
	const [isLoadingComments, setIsLoadingComments] = useState(false)
	const [applyFrontEndFilter, setApplyFrontEndFilter] = useState(false)
	const [sentSubmit, setSentSubmit] = useState(false)
	const [sentCancel, setSentCancel] = useState(false)
	const sock = useRef()
	const handleApiError = ApiError();

	const frontEndFilter = (rows) => {
		console.log("here")
		if (rows)
			return rows.filter((row) => row.id !== clickedRow.id)
	}

	//  console.log(jwtToken)
	const fetchData = async (querySettings) => {
		try {
			setIsLoading(true)
			const data = await ApiManager.QUEUE.GETQUEUE(querySettings);
			const dataAPI = await handleApiError(data);
			console.log(dataAPI)

			setRows(dataAPI)
			console.log("dataApi", dataAPI)

		} catch (error) {
			console.log('hereee queue', error);
		} finally {
			setIsLoading(false)
		}
	};

	useEffect(() => {


		console.log(`wss://${process.env.REACT_APP_SERVER_WS_ROUTE}`)
		const socket = new WebSocket(`wss://${process.env.REACT_APP_SERVER_WS_ROUTE}`);
		sock.current = socket
		sock.current.addEventListener('open', function (event) {
			console.log("WS open")

		});

		sock.current.addEventListener('message', function (event) {
			fetchData()
			console.log("Happened")

		});

		return () => {
			socket.close();
			setOpenForm(false)
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const columns = [
		{ field: 'requester', headerName: 'Requestor', minWidth: 0, flex: .1 },
		{
			field: 'request_timestamp', headerName: 'Timestamp', minWidth: 0, flex: .15,
			type: 'dateTime',
			valueGetter: ({ value }) => value && new Date(value)

		},
		{
			field: 'part_name', headerName: 'Part', minWidth: 0, flex: .15, renderCell: (params) => (
				<HoldButton url={`/part?partNum=${params.value}`} color={"#2d5ca9"} params={params} fontSize={params.row.fontSize}>
					{params.value}
				</HoldButton>
			)
		},
		{
			field: 'serial_name', headerName: 'Serial', minWidth: 0, flex: .1, renderCell: (params) => (
				<HoldButton url={`/log/${params.value}?sid=${params.row.sid}`} color={"#2d5ca9"} params={params} fontSize={params.row.fontSize}>
					{params.value}
				</HoldButton>
			)
		},
		{ field: 'subject', headerName: 'Subject', minWidth: 0, flex: .5 },
		{
			field: 'jira_ticket', headerName: 'Jira Ticket', minWidth: 0, flex: .1, renderCell: (params) => {
				if (params.value) {
					return (
						<HoldButton target="_blank" url={params.value} color={'#2d5ca9'} params={params} fontSize={params.row.fontSize}>
							<JiraIcon />
						</HoldButton>
					)
				} else {
					return (
						<div className='no_jira_icon'>
							<CloseIcon />
						</div>
					)
				}
			}
		}

	];

	const handleEvent = (e) => {
		setOpenForm(true)
		setClickRow(e.row)
		const old = document.body.scrollHeight
		setTimeout(() => {
			window.scrollTo({ left: 0, top: document.body.scrollHeight - old * 2, behavior: "smooth" });

		}, 100);
		const obj = {
			request_id: e.row.id,
			serial_number: e.row.serial_name
		};

		console.log('e', e);
		(async () => {
			try {
				const data = await ApiManager.QUEUE.GETREQUESTFILES(obj);

				const errorName = await handleApiError(data);
				console.log("queue Files", errorName);

				setCurrentFile(errorName);
			} catch (error) {
				console.log('hereee 1', error);
			};
		})();

		const obj2 = {
			request_id: e.row.id,
			serial_number: e.row.serial_name

		};
		console.log('e after', obj2);



		(async () => {
			try {
				setIsLoadingComments(true)
				const data = await ApiManager.QUEUE.GETREQUESTCOMMENT(obj2);
				const errorName = await handleApiError(data);
				console.log("queueee", errorName)
				setComments(errorName)
				setIsLoadingComments(false)
			} catch (error) {

				console.log('hereee', error);
			}
		})();


	}

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<>
			<h1 style={{ textAlign: 'center', margin: "16px 0" }}>Rework Queue</h1>
			<Table
				columns={columns}
				fetchData={() => {
					return { result: rows, size: rows.length }
				}}
				location={"tableSettings_queue"}
				onRowClick={handleEvent}
				sx={{
					"& .MuiDataGrid-root": {
						backgroundColor: "#43A6C6",
						color: "white",
						border: 'solid 2px black',
					},
					height: 700
				}}
				doFetchIndex={rows}
				id={1}
				applyFrontEndFilter={applyFrontEndFilter}
				frontEndFilter={frontEndFilter}
				setApplyFrontEndFilter={frontEndFilter}
				paginationMode={"client"}
				loading={isLoading}
			/>
			<Modal open={(openForm && rows.some((row) => row.id === clickedRow.id))} onClose={() => setOpenForm(false)}>
				<QueueForm isLoadingComments={isLoadingComments} setSentSubmit={setSentSubmit} setSentCancel={setSentCancel} data={clickedRow} onClose={() => setOpenForm(false)} applyFilter={() => setTimeout(() => setApplyFrontEndFilter(true), 0)} setOpenForm={setOpenForm} currentFile={currentFile} comments={comments} setComments={setComments} />
			</Modal>
			<CustomSnackbar
				open={sentSubmit}
				onClose={() => setSentSubmit(false)}
				borderColor={"rgb(0,128,0)"}
			>
				<SendIcon /> Successfull Submitted ECO Request
			</CustomSnackbar>
			<CustomSnackbar
				open={sentCancel}
				onClose={() => setSentCancel(false)}
				borderColor={"rgb(0,128,0)"}
			>
				<SendIcon /> Successfull Cancelled ECO Request
			</CustomSnackbar>
		</>
	)
}